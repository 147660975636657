import React from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import PastCAP from "./factoryActionNeeded.component";
import FactoryActionNeeded from "./factoryActionNeeded.component";
import TwoLineCard from "../twoLineCard.component";

const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {
  return (
    <>
      <Typography
        sx={{
          color: "#000",
          fontSize: "18px",
          fontWeight: "700",
          my: 3,
        }}
      >
        Details from Most Recent Audit
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {selectedFactory?.metadata?.tier !== "1" ? (
            <Typography
              sx={{
                color: "#000",
                fontSize: "24px",
                fontWeight: "300",
                my: 3,
                textAlign: "center",
              }}
            >
              Tier {selectedFactory?.metadata?.tier} factories are beyond the scope of audits
            </Typography>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
              <FacilityIssuesCard latestAuditData={selectedFactory.latestAudit}/>
              </Grid>
              <Grid item xs={6}>
                <TwoLineCard
                  title="Supplier Rating from Audit"
                  nextAuditDate={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: (() => {
                          const auditScore = parseInt(selectedFactory?.latestAudit?.risk?.auditScore ?? selectedFactory?.risk?.riskScore, 10);
                          if (isNaN(auditScore)) return "rgba(0, 0, 0, 0.1)";
                          if (auditScore === 1) return "rgba(255, 0, 0, 0.1)";
                          if (auditScore >= 2 && auditScore <= 3) return "rgba(255, 165, 0, 0.1)";
                          if (auditScore >= 4 && auditScore <= 5) return "rgba(0, 128, 0, 0.1)";
                          return "rgba(0, 0, 0, 0.1)";
                        })(),
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "34px",
                          fontWeight: "bold",
                          color: (() => {
                            const auditScore = parseInt(selectedFactory?.latestAudit?.risk?.auditScore ?? selectedFactory?.risk?.riskScore, 10);
                            if (isNaN(auditScore)) return "black";
                            if (auditScore === 1) return "red";
                            if (auditScore >= 2 && auditScore <= 3) return "orange";
                            if (auditScore >= 4 && auditScore <= 5) return "green";
                            return "black";
                          })(),
                          marginRight: "16px",
                        }}
                      >
                        {selectedFactory?.latestAudit?.risk?.auditScore ?? selectedFactory?.risk?.riskScore}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ marginRight: "16px", backgroundColor: "white" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "normal",
                          color: (() => {
                            const auditScore = parseInt(selectedFactory?.latestAudit?.risk?.auditScore ?? selectedFactory?.risk?.riskScore, 10);
                            if (isNaN(auditScore)) return "black";
                            if (auditScore === 1) return "red";
                            if (auditScore >= 2 && auditScore <= 3) return "orange";
                            if (auditScore >= 4 && auditScore <= 5) return "green";
                            return "black";
                          })(),
                        }}
                      >
                        {(() => {
                          const auditScore = parseInt(selectedFactory?.latestAudit?.risk?.auditScore ?? selectedFactory?.risk?.riskScore, 10);
                          if (isNaN(auditScore)) return "Coming Soon.";
                          if (auditScore === 1) return "High risk";
                          if (auditScore >= 2 && auditScore <= 3) return "Medium risk";
                          if (auditScore >= 4 && auditScore <= 5) return "Low risk";
                          return "Coming Soon.";
                        })()}
                      </Typography>
                    </Box>
                  }
                />
                <TwoLineCard
                  title="Next Audit Date"
                  nextAuditDate={selectedFactory?.metadata?.nextReportDate || "-"}
                  lastAuditDate={
                    selectedFactory?.metadata?.nextReportDate
                      ? moment(selectedFactory.metadata.nextReportDate).subtract(1, 'years').format('YYYY-MM-DD')
                      : "-"
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FactoryMostRecentAuditDetails;
