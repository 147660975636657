import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
  Typography,
} from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomChipLabel from "./customChipLabel.component";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedAuditIssueId,
  deleteCapDetailsById,
} from "../../reducers/audit.reducer";
import { selectAuditIssueCAPData } from "../../selectors/audit.selector";
import { selectEditModeState } from "../../selectors/misc.selector";
import { useParams } from "react-router-dom";

const AuditsTable = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const handleClick = (id) => {
    dispatch(setSelectedAuditIssueId(id));
  };
  const handleDelete = (capDetailsId) => {
    dispatch(
      deleteCapDetailsById({
        organisationId: params.organisationId,
        supplierId: params.supplierId,
        factoryId: params.factoryId,
        auditId: params.auditId,
        capDetailsId: capDetailsId,
      })
    );
  };

  const editMode = useSelector(selectEditModeState);
  const issuesData = useSelector(selectAuditIssueCAPData);

  return (
    <>
      <Box
        elevation={1}
        sx={{
          p: 3,
          borderTop: "0px",
          px: 5,
        }}
      >
        <Table aria-label="issues table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Issues</TableCell>
              <TableCell sx={{ width: "12px" }}></TableCell>
              <TableCell sx={{ p: 0 }}>Type</TableCell>
              <TableCell sx={{ p: 0 }}>Severity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell sx={{ p: 0 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issuesData.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    mr: 0,
                    pr: 1,
                    maxWidth: "470px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    mr: 0,
                    pr: 1,
                    maxWidth: "470px",
                  }}
                >
                  {row.issue}
                </TableCell>
                <TableCell
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                    paddingRight: "40px",
                    opacity: 0.5,
                  }}
                  onClick={() => {
                    handleClick(row.id);
                  }}
                >
                  <IconButton
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      padding: 0,
                      margin: 0,
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <OpenInFullIcon className="icon" />
                    <Typography variant="caption" sx={{ ml: 1 }}>
                      See detail
                    </Typography>
                  </IconButton>
                </TableCell>
                <TableCell sx={{ p: 0 }}>{row.type}</TableCell>
                <TableCell sx={{ p: 0 }}>
                  <CustomChipLabel severity={row.severity} />
                </TableCell>
                <TableCell>
                  <CustomChipLabel
                    cap={row.statusChipLabel}
                    capLabel={row.status}
                  />
                </TableCell>
                <TableCell sx={{ p: 1 }}>
                  {editMode && (
                    <Button onClick={() => handleDelete(row.id)}>
                      <DeleteIcon sx={{ color: "gray" }} />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default AuditsTable;
