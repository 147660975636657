import React from "react";
import { Routes, Route } from "react-router-dom";

import SupplierPage from "./supplier.page";

import Risks from "./risk.page";
import Insights from "./insight.page";
import SupplierDetails from "./supplierDetails.page";
import FacilityDetails from "./facilityDetails.page";
import AuditDetails from "./auditDetails.page";
import Factories from "./factory.page";
import InactiveFactories from "./inactiveFactory.page";

const IndexPage = () => {
  return (
    <Routes>
      <Route path="/factories" element={<Factories />} />
      <Route path="/suppliers" element={<SupplierPage />} />
      <Route path="/suppliers/:supplierId" element={<SupplierDetails />} />
      <Route
        path="/organisations/:organisationId/suppliers/:supplierId/factories/:factoryId"
        element={<FacilityDetails />}
      />
      <Route
        path="/organisations/:organisationId/suppliers/:supplierId/factories/:factoryId/audits/:auditId"
        element={<AuditDetails />}
      />
      <Route path="/risk" element={<Risks />} />
      <Route path="/insights" element={<Insights />} />
      <Route path="/inactive-factories" element={<InactiveFactories />} />
    </Routes>
  );
};

export default IndexPage;
