import React, { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
} from "@mui/material";
import TopBreadcrumbs from "../topBreadcrumbs.component";

import { debounce } from "lodash";
// import RiskScatterPlot from "../RiskDetails/riskScatterPlot.component";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  selectSearchCount,
  selectSearchResultsSupplierList,
  selectSupplierCache,
  selectSupplierList,
  selectTotalCount,
} from "../../selectors/supplier.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { BorderLeft } from "@mui/icons-material";

const SupplierMetaDataGrid = () => {
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);
  const navigateToSupplierDetails = (supplierId) => {
    dispatch(getSupplierById(supplierId));
    navigate(`${location.pathname}/${supplierId}`);
  };
  const [filteredSuppliers, setFilteredSuppliers] = useState(suppliersList);

  const rows = filteredSuppliers.map((supplier) => {
    return {
      id: supplier?.supplierId ?? "",
      name: supplier?.name ?? "",
      country: supplier?.country ?? "",
      companyAddress: supplier?.metadata?.companyAddress ?? "",
      vendorStatus: supplier?.metadata?.vendorStatus ?? "",
      contact: supplier?.metadata?.contactPerson?.contactNote ?? "",
      note: supplier?.metadata?.note ?? "",
    };
  });

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "country", headerName: "Country", width: 100, align: "center", headerAlign: "center" },
    { field: "companyAddress", headerName: "Company Address", width: 300, align: "center", headerAlign: "center" },
    { field: "vendorStatus", headerName: "Vendor Status", width: 120, align: "center", headerAlign: "center" },
    { field: "contact", headerName: "Contact", width: 300, align: "center", headerAlign: "center" },
    { field: "note", headerName: "Note", width: 200, align: "center", headerAlign: "center" },
    // {
    //   field: ".",
    //   headerName: "",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Button
    //       variant="contained"
    //       onClick={() => {
    //         // console.log("params.row.supplierId", params.row);
    //         navigateToSupplierDetails(params.row.id);
    //       }}
    //       sx={{
    //         backgroundColor: "#6172F3",
    //         color: "white",
    //         "&:hover": {
    //           backgroundColor: "#5667e2",
    //           fontWeight: "bold",
    //         },
    //         textTransform: "none",
    //         width: "79px",
    //         height: "40px",
    //         borderRadius: "8px",
    //       }}
    //       size="small"
    //     >
    //       Details
    //     </Button>
    //   ),
    // },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const totalCount = useSelector(selectTotalCount);
  const searchResults = useSelector(selectSearchResultsSupplierList);
  const searchCount = useSelector(selectSearchCount);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState(totalCount);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const supplierCache = useSelector((state) =>
    selectSupplierCache(state, page)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm !== "") {
      setCount(searchCount);
    } else {
      setCount(totalCount);
    }
  }, [totalCount, searchCount]);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredSuppliers(searchResults);
    } else {
      setFilteredSuppliers(suppliersList);
    }
  }, [searchTerm, searchResults, suppliersList]);

  useEffect(() => {
    if (supplierCache) {
      setFilteredSuppliers(supplierCache);
    } else {
      const limit = 8;
      const offset = (page - 1) * limit;
      dispatch(getListOfAllSuppliers({ page, limit, offset }));
    }
  }, [page, supplierCache, dispatch]);

  const location = useLocation();

  return (
    <Box sx={{ height: "calc(100vh - 250px)", width: "100%", padding: "24px 80px"}}>
      <DataGrid
        {...dataGridFormat}
        disableDensitySelector
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          borderRadius: "8px",
          padding: "16px",
          "& .MuiDataGrid-cell": {
            padding: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0", // Light gray background
            color: "#333", // Dark text color
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
        componentsProps={{
          baseCheckbox: {
            sx: {
              width: "100px",
            },
          },
        }}
      />
      

    </Box>
    
  );
};

export default SupplierMetaDataGrid;
