import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Divider, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSupplierData } from "../../selectors/supplier.selector";
import SupplierInformationEditModal from "./EditModals/editSupplierInformation.component";
import EditIcon from "@mui/icons-material/Edit";
import { selectEditModeState } from "../../selectors/misc.selector";

const SupplierInformation = () => {
  const supplierData = useSelector(selectSupplierData);

  const [isEditing, setIsEditing] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const editMode = useSelector(selectEditModeState);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Card
      style={{
        padding: "0px 32px",
        boxShadow:
          "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
        border: "1px solid rgba(234, 236, 240, 1)",
        borderRadius: "12px",
        minHeight: "262px",
      }}
    >
      <SupplierInformationEditModal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        supplierData={supplierData}
      />

      {supplierData && (
        <CardContent>
          <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: "#344054",
                  display: "inline-block",
                }}
              >
                General Vendor Information
              </Typography>
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={{ ml: 1, cursor: "pointer", color: "#585aeb" }}
                  onClick={toggleEdit}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* Larger Column with General Information */}
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#8B8D97",
                    }}
                  >
                    Contact Information
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#45464E",
                      wordBreak: "break-word",
                      whiteSpace: "pre-line", // Ensures new lines are rendered
                      maxHeight: showMore ? "none" : "100px", // Fixed height with show more option
                      overflow: "hidden",
                    }}
                  >
                    {supplierData?.metadata?.contactPerson?.contactNote
                      ? supplierData.metadata.contactPerson.contactNote
                          .split("\n")
                          .map((line, index) => (
                            <span key={index}>
                              {line.includes("Name:") && <strong>Name:</strong>}
                              {line.includes("Function:") && <strong>Function:</strong>}
                              {line.includes("Email:") && <strong>Email:</strong>}
                              {line.replace(/(Name:|Function:|Email:)/g, "")}
                              <br />
                            </span>
                          ))
                      : "No contact information available"}
                  </Typography>
                  <Button onClick={toggleShowMore} sx={{ mt: 1 }}>
                    {showMore ? "Show Less" : "Show More Contacts"}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#8B8D97",
                    }}
                  >
                    Vendor Status
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#45464E",
                      wordBreak: "break-word",
                    }}
                  >
                    {supplierData?.metadata?.status || supplierData?.metadata?.vendorStatus  || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Divider between columns */}
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            {/* Smaller Column with Alias and Notes */}
            <Grid item xs={3}>
              {supplierData?.metadata?.alias && (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8B8D97",
                  }}
                >
                  Alias
                </Typography>
              )}
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#45464E",
                  wordBreak: "break-word",
                  mb: 2,
                }}
              >
                {supplierData?.metadata?.alias || ""}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#8B8D97",
                }}
              >
                StitchFix Notes
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#45464E",
                  wordBreak: "break-word",
                  fontStyle: supplierData?.metadata?.note ? "normal" : "italic",
                  color: supplierData?.metadata?.note ? "#45464E" : "#8B8D97",
                  whiteSpace: "pre-line", // Ensures new lines are rendered
                }}
              >
                {supplierData?.metadata?.note || "No notes added"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default SupplierInformation;
