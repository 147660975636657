import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { debounce } from "lodash";
import moment from "moment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
} from "../../selectors/factory.selector";
import { selectLoadingState } from "../../selectors/misc.selector";

const FacilityMetaDataGrid = () => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  const rows = factoriesList.map((factory) => {
    return {
      id: factory?.factoryId ?? "",
      factoryId: factory?.metadata?.factoryId ?? "",
      name: factory?.name ?? "",
      status: factory?.metadata?.status ?? "",
      supplierName: factory?.supplierName ?? "",
      country: factory?.metadata?.country ?? "",
      tier: factory?.metadata?.tier ?? "",
      address: factory?.metadata?.address ?? "",
      factoryApprovalDate: factory?.metadata?.factoryApprovalDate ? moment(factory.metadata.factoryApprovalDate).format('YYYY-MM-DD') : '',
      associatedSupplier: factory?.supplierName ?? "",
      materialProduced: factory?.metadata?.materialProduced ?? "",
      productCategories: factory?.metadata?.productCategories ?? "",
      highStrikeRisk: factory?.metadata?.highStrikeRisk ?? "",
      agent: factory?.metadata?.agent ?? "",
      apparelNonApparel: factory?.metadata?.productCategories ?? "",
      businessLine: factory?.businessUnit ?? "",
      brandType: factory?.metadata?.productCategories ?? "",
      countryOfOrigin: factory?.metadata?.country ?? "",
      factoryNickname: factory?.metadata?.alias ?? "",
      factoryLegalName: factory?.name ?? "",
      factoryStatus: factory?.metadata?.status ?? "",
      vendorLegalName: factory?.supplierName ?? "",
      vendorStatus: factory?.metadata?.status ?? "",
      auditScore: factory?.riskScore ?? "",
      highRiskStrike: factory?.metadata?.highStrikeRisk ?? "",
      nextAudit: factory?.metadata?.nextReportDate ? moment(factory.metadata.nextReportDate).format('MM-YY') : '',
      auditStandard: factory?.metadata?.auditType ?? "",
      noOfWorkers: factory?.metadata?.workerCount ?? "",
      address1: factory?.metadata?.address ?? "",
      factoryApprovedOn: factory?.metadata?.factoryApprovalDate ? moment(factory.metadata.factoryApprovalDate).format('YYYY-MM-DD') : '',
    };
  });

  const columns = [
    { field: "apparelNonApparel", headerName: "Apparel / Non Apparel", width: 200, align: "center", headerAlign: "center" },
    { field: "businessLine", headerName: "Business Line", width: 150, align: "center", headerAlign: "center" },
    { field: "brandType", headerName: "Brand Type", width: 150, align: "center", headerAlign: "center" },
    { field: "countryOfOrigin", headerName: "Country of Origin", width: 150, align: "center", headerAlign: "center" },
    { field: "factoryNickname", headerName: "Factory Nickname", width: 150, align: "center", headerAlign: "center" },
    { field: "factoryLegalName", headerName: "Factory Legal Name", width: 200, align: "center", headerAlign: "center" },
    { field: "factoryId", headerName: "Factory ID", width: 150, align: "center", headerAlign: "center" },
    { field: "factoryStatus", headerName: "Factory Status", width: 150, align: "center", headerAlign: "center", type: 'singleSelect', valueOptions: ['Approved', 'Pending', 'Inactive'] },
    { field: "vendorLegalName", headerName: "Vendor Legal Name", width: 200, align: "center", headerAlign: "center" },
    { field: "agent", headerName: "Agent", width: 150, align: "center", headerAlign: "center" },
    { field: "vendorStatus", headerName: "Vendor Status", width: 150, align: "center", headerAlign: "center" },
    { field: "auditScore", headerName: "Audit Score", width: 150, align: "center", headerAlign: "center" },
    { field: "highRiskStrike", headerName: "High Risk Strike", width: 150, align: "center", headerAlign: "center" },
    { field: "nextAudit", headerName: "Next Audit (MM-YY)", width: 150, align: "center", headerAlign: "center" },
    { field: "auditStandard", headerName: "Audit Standard", width: 150, align: "center", headerAlign: "center" },
    { field: "noOfWorkers", headerName: "No. of Workers", width: 150, align: "center", headerAlign: "center" },
    { field: "tier", headerName: "Tier", width: 150, align: "center", headerAlign: "center" },
    { field: "address1", headerName: "Address 1", width: 300, align: "center", headerAlign: "center" },
    { field: "factoryApprovedOn", headerName: "Factory Approved On", width: 200, align: "center", headerAlign: "center" },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
    filter: {
      filterModel: {
        items: [{ field: 'factoryStatus', operator: 'is', value: 'Approved' }],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFactories, setFilteredFactories] = useState([]);

  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]);

  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      dispatch(getSearchResultOfFactories({ search: term }));
    }, 100),
    []
  );

  const location = useLocation();

  return (
    <Box sx={{ height: "calc(100vh - 220px)", width: "100%", padding: "10px 30px" }}>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          {[...Array(10)].map((_, index) => (
            <Skeleton
              key={index}
              height={60}
              animation="wave"
              sx={{ my: 1 }}
            />
          ))}
        </Box>
      ) : (
        <DataGrid
          {...dataGridFormat}
          checkboxSelection
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowHeight={() => "auto"}
          sx={{
            borderRadius: "8px",
            padding: "16px",
            "& .MuiDataGrid-cell": {
              padding: "8px",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "none !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f0f0f0",
              color: "#333",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnHeader": {
              padding: "16px",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        />
      )}
    </Box>
  );
};

export default FacilityMetaDataGrid;
